import './App.css';

function Card(props) {
  const { className, name, title, email, bg } = props;
  return (
    <div className={`Card ${className}`} style={{ backgroundImage: bg }}>
      <div className="inner">
        <div className="company">
          <h1>Gomez, Allen &amp; Kwiatek</h1>
          <h2>Electronic Video Games</h2>
        </div>
        <div className="email">
          {email}
          <span>@</span>gak.llc
        </div>
        <div className="location">
          <div>cliquesgame.com &middot; discord.gg/theclique</div>
          {/* <div>New York, NY &middot; Chicago, IL &middot; Raleigh, NC</div> */}
        </div>
      </div>
      <div className="identity">
        <div className="name">{name}</div>
        <div className="title">{title}</div>
      </div>
    </div>
  );
}

function App() {
  const cssurl = (file) => `url(${process.env.PUBLIC_URL}/${file})`;
  return (
    <div className="App">
      <div
        style={{
          position: 'absolute',
          inset: 0,
          // backgroundImage: cssurl('pexels-fwstudio-172276.jpg'),
          backgroundColor: '#000',
          backgroundSize: 'cover',
          // filter: 'hue-rotate(-10deg) brightness(0.6) contrast(1.3) sepia(0.2)',
        }}
      />
      <Card
        className="one"
        name="Zack GOMEZ"
        title="Vice President"
        email="zack"
        bg={cssurl('pexels-eva-elijas-7598248.jpg')}
      />
      <Card
        className="two"
        name="Rob ALLEN"
        title="Vice President"
        email="rob"
        bg={cssurl('white_linen_metallic.jpg')}
      />
      <Card
        className="three"
        name="Nick KWIATEK"
        title="Vice President"
        email="nick"
        bg={cssurl('pexels-sasha-martynov-1260727.jpg')}
      />
    </div>
  );
}

export default App;
